type TCreateElementOptions = { [attr: string]: string } & {
    tagName?: string;
    innerHTML?: string;
};

export const createElement: (options: TCreateElementOptions) => HTMLElement = (options) => {
    const { tagName = "div", innerHTML, ...attributes } = options ?? {};

    const element = document.createElement(tagName);

    if (attributes !== undefined) {
        Object.entries(attributes).forEach(([key, value]) => {
            element.setAttribute(key, value);
        });
    }

    if (typeof innerHTML === "string") {
        element.innerHTML = innerHTML;
    }

    return element;
};
