import "core-js/es/number/is-nan.js";
import "core-js/es/object/entries.js";

import jss from "jss";

import { createElement } from "../utils";

import makeStyles from "./browsersSupport.styles";

const allScripts = document.getElementsByTagName("script");
// Fallback for IE: at the moment of execution current script is last one in list
const currentScript = document.currentScript || allScripts[allScripts.length - 1];

const presetMap = {
    modern: process.env.MODERN_BROWSERS_SUPPORT,
    legacy: process.env.LEGACY_BROWSERS_SUPPORT,
};

const defaultMessageHTML =
    'You are using an unsupported browser. Please upgrade it in order to get full experience. If you have any questions, please contact our customer support <a href="mailto:support@climatepartner.com" target="_blank">support@climatepartner.com</a>.';

function checkBrowserComparability() {
    // Dataset is not supported on older IEs
    const dataOffset = currentScript?.getAttribute("data-offset");
    const dataPreset = currentScript?.getAttribute("data-preset") as keyof typeof presetMap;
    const dataMessageHTML = currentScript?.getAttribute("data-messageHTML");
    const dataBrowsers = currentScript?.getAttribute("data-browsers");

    const offset = dataOffset ? Number.parseInt(dataOffset) : 72;
    const preset = dataPreset ?? "modern";
    const messageHTML = dataMessageHTML ?? defaultMessageHTML;

    let browsersRegExp = Object.prototype.hasOwnProperty.call(presetMap, preset) ? presetMap[preset] : presetMap.modern;

    if (typeof dataBrowsers === "string" && dataBrowsers !== "") {
        browsersRegExp = new RegExp(dataBrowsers);
    }

    const isBrowserSupported: boolean = browsersRegExp.test(navigator.userAgent);

    if (!isBrowserSupported) {
        const { classes } = jss.createStyleSheet(makeStyles({ offset })).attach();

        const notificationElement = createElement({ class: classes.notification });
        const messageElement = createElement({ class: classes.message, innerHTML: messageHTML });
        const actionElement = createElement({ class: classes.action });
        const buttonElement = createElement({
            tagName: "button",
            class: classes.button,
            type: "button",
            tabindex: "0",
            innerHTML: "✕",
        });

        notificationElement.appendChild(messageElement);
        notificationElement.appendChild(actionElement);
        actionElement.appendChild(buttonElement);
        document.body.appendChild(notificationElement);

        buttonElement.addEventListener("click", () => {
            notificationElement.className = notificationElement.className.replace(/\bin\b/, "");
        });

        // Show animated message
        setTimeout(() => {
            notificationElement.className += " in";
        }, 250);
    }
}

window.addEventListener("load", checkBrowserComparability);
