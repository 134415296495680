import jss from "jss";
import preset from "jss-preset-default";

// One time setup with default plugins and settings.
jss.setup(preset());

const makeStyles = ({ offset }: { offset: string | number }) => ({
    notification: {
        position: "absolute",
        top: -100,
        left: "50%",
        zIndex: 1400,
        transform: "translateX(-50%)",

        opacity: 0,
        color: "#fff",
        padding: "6px 16px",
        fontSize: "1rem",
        boxShadow:
            "0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
        alignItems: "center",
        borderRadius: 4,
        backgroundColor: "#003c91",

        transition: "225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        transitionProperty: "top, opacity",

        "& a": {
            display: "inline",
            color: "inherit",
            textDecoration: "underline",
        },

        "@media (min-width: 600px)": {
            minWidth: 288,
        },

        "&.in": {
            top: offset,
            opacity: 1,
        },

        "& *": {
            boxSizing: "border-box",
        },
    },
    message: {
        padding: "8px 0",
        paddingRight: `${16 + 24}px`,
    },
    action: {
        position: "absolute",
        top: "50%",
        right: "16px",

        transform: "translateY(-50%)",
    },
    button: {
        display: "inline-block",
        width: 24,
        height: 24,
        margin: 0,
        padding: 0,
        border: 0,

        color: "inherit",
        cursor: "pointer",
        outline: 0,
        userSelect: "none",
        verticalAlign: "middle",
        appearance: "none",
        backgroundColor: "transparent",
        fontSize: "1.5rem",

        textAlign: "center",
    },
});

export default makeStyles;
